import styles from './BlockquoteSlide.module.scss'
import { BlockquoteSliderProps } from 'components/slider-section/blockquote-slide/types'
import { useTranslation } from 'next-i18next'

const BlockquoteSlide = ({ quote, namespace, caption }: BlockquoteSliderProps) => {
  // i18n
  const { t } = useTranslation(namespace)

  return (
    <blockquote className={styles.blockquote}>
      <p className={styles.quote} suppressHydrationWarning>
        {namespace ? t(quote) : quote}
      </p>
      {caption && (
        <footer className={styles.footer}>
          {!!caption.author && <cite className={styles.cite}>{namespace ? t(caption.author) : caption.author}</cite>}
          {!!caption.extra && <small className={styles.extra}>{namespace ? t(caption.extra) : caption.extra}</small>}
          {!!caption.logo && caption.logo}
        </footer>
      )}
    </blockquote>
  )
}

export default BlockquoteSlide
