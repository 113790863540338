import { useRef, useState } from 'react'
import styles from './SliderSection.module.scss'
import SlickSlider from 'react-slick'
import classNames from 'classnames'
import Img from 'components/img/Img'
import { SliderSectionProps } from 'components/slider-section/types'

const SliderSection = ({ slides }: SliderSectionProps) => {
  // State
  const [activeSlide, setActiveSlide] = useState<number>(0)

  // Ref
  const contentSliderRef = useRef(null)
  const imgSliderRef = useRef(null)

  // Constants
  const hasMultipleSlides = slides.length > 1

  return (
    <section className={styles['slider-section']}>
      <SlickSlider
        ref={imgSliderRef}
        slidesToShow={1}
        dots={false}
        fade
        arrows={false}
        speed={1000}
        draggable={false}
        infinite={hasMultipleSlides}
        autoplay={hasMultipleSlides}
      >
        {slides.map(({ id, img }) => (
          <picture key={`${id}-img`}>
            <Img fill publicId={img} alt={id} sizes="(min-width: 920px) 50vw, 100vw" />
          </picture>
        ))}
      </SlickSlider>

      <div className={styles['slider-wrapper']}>
        <SlickSlider
          ref={contentSliderRef}
          slidesToShow={1}
          dots={false}
          infinite={hasMultipleSlides}
          autoplay={hasMultipleSlides}
          pauseOnHover
          arrows={false}
          autoplaySpeed={4000}
          speed={1000}
          draggable={false}
          beforeChange={(curr, next) => {
            setActiveSlide(next)
            imgSliderRef?.current?.slickGoTo(next)
          }}
        >
          {slides.map(({ id, content }) => (
            <div key={id} className={styles['slide-wrapper']}>
              {content}
            </div>
          ))}
        </SlickSlider>

        {hasMultipleSlides && (
          <nav>
            <ul className={styles['custom-pagination']}>
              {slides.map(({ id }, navIdx) => (
                <li key={`dot-${id}`}>
                  <button
                    aria-label={`Slide ${navIdx + 1} navigation button`}
                    onClick={() => {
                      contentSliderRef?.current?.slickGoTo(navIdx)
                      imgSliderRef?.current?.slickGoTo(navIdx)
                    }}
                    className={classNames(styles.dot, { [styles.active]: activeSlide === navIdx })}
                  />
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </section>
  )
}

export default SliderSection
