import { CallToActionBlockProps } from 'components/call-to-action-block/types'
import Img from 'components/img/Img'
import Card from 'components/card/Card'
import styles from './CallToActionBlock.module.scss'
import { CardColors } from 'components/card/types'
import classNames from 'classnames'
import CallToActionWrapper from './CallToActionWrapper'
import { Heading } from '@boltenergy-be/design-system'

const CallToActionBlock = ({ action, color, description, image, title }: CallToActionBlockProps) => {
  return (
    <CallToActionWrapper {...{ action }}>
      {/* Image */}
      {image && (
        <figure className={classNames(styles.figure, image.className)}>
          <Img publicId={image.cloudinaryId} alt={image.alt} fill />
        </figure>
      )}

      <Card compact color={color ?? CardColors.YELLOW} className={styles.content}>
        {/* Title */}
        <Heading as={title.as || 'h2'} variant="h4">
          {title.text}
        </Heading>

        {/* Description */}
        {description && <p>{description}</p>}

        {/* Action */}
        {!!action.label && <span className={styles['as-button']}>{action.label}</span>}
      </Card>
    </CallToActionWrapper>
  )
}

export default CallToActionBlock
