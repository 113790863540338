import { FC } from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import styles from './EncyclopediaNavigationMenu.module.scss'
import { NavigationMenuProps as Props } from './types'
import Icon from 'components/icon/Icon'
import { Heading } from '@boltenergy-be/design-system'

const EncyclopediaNavigationMenu: FC<Props> = ({ categories }) => {
  // I18n
  const { t } = useTranslation('home')

  return (
    <div className={classNames('container', styles['encyclopedia-navigation-menu'])}>
      <Heading as="h2" variant="h3">
        {t('encyclopediaTitle', "Bolt's energie-encyclopedie")}
      </Heading>

      <nav className={styles.menu}>
        {Object.keys(categories).map((category, categoryIndex) => {
          return (
            <div key={category} className={styles['category-group']}>
              <h3 className="h4" style={{ textDecorationColor: categories[category].color ?? 'unset' }}>
                <Link href={categories[category].slug}>{category}</Link>
              </h3>

              <ul className={styles['sub-category-group']}>
                {categories[category].subCategories.map((subCat) => {
                  return (
                    <li key={subCat.title}>
                      <Link
                        href={subCat.slug}
                        className={classNames(styles.link, {
                          [styles.pink]: categoryIndex === 0,
                          [styles.orange]: categoryIndex === 1,
                          [styles.green]: categoryIndex === 2
                        })}
                      >
                        {subCat.title}
                        <Icon name="arrowRight" />
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </nav>
    </div>
  )
}

export default EncyclopediaNavigationMenu
