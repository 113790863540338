import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Button } from '@boltenergy-be/design-system'
import EncyclopediaNavigationMenu from 'components/encyclopedia/navigation-menu/EncyclopediaNavigationMenu'
import parse from 'html-react-parser'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { Language } from 'types/language'
import { routes } from 'utils/routes'
import styles from './Home.module.scss'
import { HomeHeroImage, HomeProps } from './types'
import StartSimulationForm from 'features/home/start-simulation-form/StartSimulationForm'
import { TESTIMONIALS_FR, TESTIMONIALS_NL } from './constants'
import StickyOffering from 'components/sections/bolt-offering/sticky-offering/StickyOffering'
import Img from 'components/img/Img'
import Video from 'components/video/Video'
import { useKonami } from 'react-konami-code'
import { Heading } from '@boltenergy-be/design-system'
import SliderSection from 'components/slider-section/SliderSection'
import SplitSection from 'components/split-section/SplitSection'
import useWindowSize from 'hooks/useWindowSize'
import { ArticleShowcase, ProducerShowcase } from 'components/showcase'
import CallToActionBlock from 'components/call-to-action-block/CallToActionBlock'
import { CardColors } from 'components/card/types'
import Link from 'components/link/Link'

const Home = ({ categories, articles, producers, heroImage }: HomeProps) => {
  // Next router
  const router = useRouter()
  const { locale } = router

  // i18n
  const { t } = useTranslation(['home', 'lookingForHelp', 'simulation', 'commonFormFields', 'common'])

  // Local state
  const [heroImg, setHeroImg] = useState<string | HomeHeroImage>(heroImage[locale])

  // Check window size
  const { isMobile, isTablet } = useWindowSize()

  // Change hero image on locale change
  useEffect(() => {
    setHeroImg(heroImage[locale])
  }, [heroImage, locale])

  // Easter egg
  useKonami(() => setHeroImg('website/home/groen-beleven-easter-egg'), { code: [37, 39, 37, 39, 38, 38, 40, 40] })
  useKonami(() => setHeroImg('website/home/groen-beleven-easter-egg'), { code: [67, 79, 79, 76, 84, 72, 79, 77, 65, 83] })

  /**
   * Triggered on first render
   */
  useEffect(() => {
    // Add is-home class to body
    document.body.classList.add('is-home')

    return () => {
      document.body.classList.remove('is-home')
    }
  }, [])

  return (
    <main className={styles.main}>
      {/* HERO SECTION */}
      <section className={styles.hero}>
        <div className={classNames('container', styles['hero-text-container'])}>
          <div>
            {/* TITLE */}
            <h1 className={classNames(styles.title, { [styles[locale]]: !isMobile })}>
              {parse(t('slogan', 'Weet van waar<br/><span>je stroom komt</span>'))}
            </h1>
          </div>
        </div>

        {/* CTA (Simulation & registration entry point) */}
        <StartSimulationForm />

        <picture className={styles['hero-image-container']}>
          <Img
            priority
            fill
            publicId={typeof heroImg === 'string' ? heroImg : heroImg.src}
            sizes="100vw"
            steps={800}
            objectFit={typeof heroImg === 'string' ? 'cover' : heroImg[isMobile ? 'mobile' : 'desktop'].objectFit}
            objectPosition={typeof heroImg === 'string' ? 'center 33%' : heroImg[isMobile ? 'mobile' : 'desktop'].objectPosition}
            alt="Bolt Energie"
          />
        </picture>
      </section>

      <div className={styles.dark}>
        {/* WHAT IS BOLT? */}
        <section className={classNames('section container', styles.intro, { [styles['extra-padding']]: locale === Language.FRENCH })}>
          <div>
            <Heading as="h2" variant="h3">
              {t('whatIsBolt', 'Wat is Bolt?')}
            </Heading>
            <p className="mt-300">
              {t(
                'whatIsBoltExplained',
                'Bolt is een energieleverancier en laat je helemaal zelf kiezen waar je energie vandaan komt. Is het nu via windmolens, zonnepanelen of biogas.'
              )}
            </p>
          </div>

          <Video
            id="home-video"
            height={450}
            posterSeconds={locale === Language.FRENCH ? '0' : '28'}
            publicId={`website/videos/home-${locale === Language.FRENCH ? 'fr-wallonia-launch-2024' : 'nl'}`}
            width={800}
          />
        </section>
      </div>

      {/* CALL TO ACTION - FESTIVALS */}
      <div className={styles.dark}>
        <section className={classNames('section container', styles['cta-block'])}>
          <CallToActionBlock
            action={{
              href: routes(locale).blog,
              label: t('ctaBlocks.festivals.action')
            }}
            color={CardColors.PRIMARY}
            image={{ alt: 'Windmill', className: styles['cta-image'], cloudinaryId: 'website/festivals/header_moving' }}
            title={{ text: t('ctaBlocks.festivals.title') }}
          />
        </section>
      </div>

      <div className={styles.dark}>
        {/* OFFERING */}
        <StickyOffering title={t('offering.title', 'Wat krijg je bij Bolt?')} />
      </div>

      {/* PRODUCERS SHOWCASE */}
      <section className="section">
        <div className="container">
          <Heading as="h2" variant="h3">
            {t('featuredProducers', 'Producenten in de schijnwerpers')}
          </Heading>
          <p className={classNames(styles.subtitle, 'text-lg', styles.readable)}>
            {t('featuredProducersSubtitle', 'Ontdek hoeveel jij kan besparen bij jouw favoriete producent.')}
          </p>
          <ProducerShowcase {...{ producers }} />
          <Button
            onClick={() => router.push(routes(locale).producers)}
            isFullwidthMobile
            trailingIcon="arrowRight"
            variant="primary-on-accent"
          >
            {t('featuredProducersCTA', 'Bekijk al onze producenten')}
          </Button>
        </div>
      </section>

      {/* ENCYCLOPEDIA */}
      {locale === Language.DUTCH && (
        <section className="section is-sandwished">
          <EncyclopediaNavigationMenu categories={categories} />
        </section>
      )}

      {/* NEWS */}
      {locale === Language.DUTCH && articles.length > 0 && (
        <section className="section container">
          <Heading as="h2" variant="h3">
            Laatste nieuws
          </Heading>
          <ArticleShowcase {...{ articles }} />
        </section>
      )}

      {/* TESTIMONIALS */}
      <SliderSection slides={locale === Language.DUTCH ? TESTIMONIALS_NL : TESTIMONIALS_FR} />

      {/* BECOME A PRODUCER */}
      <SplitSection className={styles['producer']}>
        <SplitSection.Left className={classNames(styles['producer-left'])}>
          <Heading as="h2" variant="h3">
            {t('areYouProducerTitle', 'Wil jij energie-opwekker worden bij Bolt?')}
          </Heading>

          <p className={classNames(styles.readable, styles['is-auto-center'], styles.info)}>
            {t(
              'areYouProducerSubtitle',
              'Fantastisch! Door je duurzame energie én garanties van oorsprong (gvo’s) via Bolt te verkopen, weet je zeker dat je stroom lokaal afgenomen wordt. Om energie-opwekker te worden heb je een industriële installatie nodig om zonne-, wind-, water- of bio-energie mee op te wekken. De installatie moet een vermogen van minstens 10 kva hebben, en een apart EAN-nummer voor injectie.'
            )}
          </p>

          <Link representation="button" href={routes(locale).becomeProducer}>
            {t('areYouProducerCTA', 'Lees meer over opwekker worden')}
          </Link>
        </SplitSection.Left>
        <SplitSection.Right className={styles['producer-right']}>
          <Img
            className={styles['producer-img']}
            id="become-producer"
            publicId={isTablet ? `website/home/become-producer-mobile-${locale}` : `website/home/become-producer-desktop-${locale}`}
            fill={false}
          />
        </SplitSection.Right>
      </SplitSection>
    </main>
  )
}

export default Home
