import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Home from 'features/home/Home'
import Layout from 'components/layout/Layout'
import { fetchProducersToShowcase } from 'api/producer'
import { getEncyclopediaSubCategories } from 'api/cms/encyclopedia'
import { getBlogArticles } from 'api/cms/blog'
import { CombinedCategories } from 'components/encyclopedia/navigation-menu/types'
import { routes } from 'utils/routes'
import { Language } from 'types/language'
import { DEFAULT_NAMESPACES, PRODUCERS_NAMESPACES } from 'constants/i18n'
import { getRandomHeroImages } from 'features/home/utils'
import { useTranslation } from 'next-i18next'
import type { HomeProps } from 'features/home/types'
import type { SeoProps } from 'components/seo/types'
import { sortBlogArticlesOnPublishedDate } from 'utils/articles'

const HomePage = (props: HomeProps) => {
  // i18n
  const { t } = useTranslation('home')

  // Constants
  const SEO: SeoProps = {
    description: t('seoDescription'),
    title: t('seoTitle')
  }

  return (
    <Layout seo={SEO}>
      <Home {...props} />
    </Layout>
  )
}

export const getStaticProps = async ({ locale }) => {
  const [producersToShow, subCategories, blogArticles] = await Promise.all([
    fetchProducersToShowcase({ language: locale }),
    getEncyclopediaSubCategories(),
    getBlogArticles(`locale=${locale}`)
  ])

  const categories = subCategories.reduce((prev: CombinedCategories, curr) => {
    if (!prev) {
      prev = {}
    }

    // if the category has no title or is not there, then skip the entry
    if (!curr?.category?.title) {
      return prev
    }

    const subCategorySlug = routes(Language.DUTCH, '', [curr.category.slug, curr.slug]).encyclopediaSubCategory

    // if the category is not in the return value yet, then add it
    if (!prev[curr.category.title]) {
      return {
        ...prev,
        [curr.category.title]: {
          color: curr.category.color,
          slug: routes(Language.DUTCH, curr.category.slug).encyclopediaCategory,
          subCategories: [{ slug: subCategorySlug, title: curr.title }]
        }
      }
    }

    // add the subCategory to the category object
    prev[curr.category.title].subCategories.push({ slug: subCategorySlug, title: curr.title })

    return prev
  }, {})

  return {
    props: {
      articles: sortBlogArticlesOnPublishedDate(blogArticles, 4),
      categories,
      heroImage: getRandomHeroImages(),
      producers: producersToShow,
      ...(await serverSideTranslations(locale, [...DEFAULT_NAMESPACES, 'home', 'simulation', ...PRODUCERS_NAMESPACES]))
    },
    revalidate: 600 // re-generate page when a request comes in, once every 10 minutes
  }
}

export default HomePage
