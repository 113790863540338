import { PropsWithChildren } from 'react'
import { CallToActionBlockProps } from './types'
import styles from './CallToActionBlock.module.scss'
import Link from 'next/link'

const CallToActionWrapper = ({ children, action }: PropsWithChildren<Pick<CallToActionBlockProps, 'action'>>) => {
  // Constants
  const className = styles['call-to-action-block']

  return action.href ? (
    <Link className={className} href={action.href} onClick={action.mixpanelTracking ? action.mixpanelTracking : undefined}>
      {children}
    </Link>
  ) : (
    <div
      className={className}
      tabIndex={-1}
      onClick={() => {
        action.mixpanelTracking && action.mixpanelTracking()
        action.onClick()
      }}
    >
      {children}
    </div>
  )
}

export default CallToActionWrapper
