import { Language } from 'types/language'
import { HeroImages } from 'features/home/types'
import { Greenpeace, Lecho, Lesoir, Nieuwsblad, Testaankoop, Testachats, Tijd } from 'components/news-articles/logos'
import { SlideType } from 'components/slider-section/types'
import BlockquoteSlide from 'components/slider-section/blockquote-slide/BlockquoteSlide'

export const HERO_IMAGES: HeroImages = {
  [Language.DUTCH]: [
    /*
    {
      src: 'website/home/spring-campaign-2024',
      position: 'center top'
    },
    'website/home/spring-campaign-2024-thierry-updated',
    {
      src: 'website/home/spring-campaign-2024-luk',
      position: 'center'
    }
    */
    {
      src: 'website/home/summer-campaign-2024',
      desktop: {
        objectFit: 'cover',
        objectPosition: 'center 20%'
      },
      mobile: {
        objectFit: 'none',
        objectPosition: '70% 100%'
      }
    }
  ],
  [Language.FRENCH]: [
    // 'website/producers/stijn',
    // 'website/producers/yvan',
    //'website/home/spring-campaign-2024-thierry-updated',
    // 'website/producers/chris_jeroen',
    // 'website/producers/bart_marijke',
    // 'website/producers/rika',
    {
      src: 'website/home/wallonia-launch-2024',
      desktop: {
        objectFit: 'cover',
        objectPosition: 'center center'
      },
      mobile: {
        objectFit: 'none',
        objectPosition: '40% center'
      }
    }
  ]
}

export const TESTIMONIALS_NL: SlideType[] = [
  {
    id: 'testaankoop',
    img: 'website/home/groen-beleven',
    content: (
      <BlockquoteSlide
        namespace="home"
        quote="testimonial.testaankoop.text"
        caption={{
          logo: <Testaankoop />
        }}
      />
    )
  },
  {
    id: 'greenpeace',
    img: 'website/producers/francis',
    content: (
      <BlockquoteSlide
        namespace="home"
        quote="testimonial.greenpeace.text"
        caption={{
          logo: <Greenpeace />
        }}
      />
    )
  },
  {
    id: 'tijd',
    img: 'website/producers/kevin-fatih',
    content: (
      <BlockquoteSlide
        namespace="home"
        quote="testimonial.tijd.text"
        caption={{
          logo: <Tijd />
        }}
      />
    )
  },
  {
    id: 'nieuwsblad',
    img: 'website/producers/noel-co',
    content: (
      <BlockquoteSlide
        namespace="home"
        quote="testimonial.nieuwsblad.text"
        caption={{
          logo: <Nieuwsblad />
        }}
      />
    )
  }
]

export const TESTIMONIALS_FR: SlideType[] = [
  {
    id: 'testachats',
    img: 'website/home/groen-beleven-5',
    content: (
      <BlockquoteSlide
        namespace="home"
        quote="home:testimonial.testachats.text"
        caption={{
          logo: <Testachats />
        }}
      />
    )
  },
  {
    id: 'greenpeace',
    img: 'website/producers/francis',
    content: (
      <BlockquoteSlide
        namespace="home"
        quote="testimonial.greenpeace.text"
        caption={{
          logo: <Greenpeace />
        }}
      />
    )
  },
  {
    id: 'lecho',
    img: 'website/producers/kevin-fatih',
    content: (
      <BlockquoteSlide
        namespace="home"
        quote="testimonial.lecho.text"
        caption={{
          logo: <Lecho />
        }}
      />
    )
  },
  {
    id: 'lesoir',
    img: 'website/producers/noel-co',
    content: (
      <BlockquoteSlide
        namespace="home"
        quote="testimonial.lesoir.text"
        caption={{
          logo: <Lesoir />
        }}
      />
    )
  }
]
